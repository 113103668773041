import React from "react";
import {Link } from 'react-router-dom'
//import { Webdevelopment } from "./Unitas-Services/Web-Development"
export const Services = () => {
  return (
    <div>
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0"></div>
      </div>
      <div className="container-fluid position-relative p-0">
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Services</h1>
              <a href="#" className="h5 text-white">
                Home
              </a>
              <i className="far fa-circle text-white px-2"></i>
              <a href="#" className="h5 text-white">
                Services
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
                <h1 className="mb-0">Custom IT Solutions for Your Successful Business</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fa fa-shield-alt text-white"></i>
                        </div>
                        <h4 className="mb-3">Web Development</h4>
                        <p className="m-0">We Specialize in crafting web development solutions. From cutting-edge tech stack Front-end and Back-end solutions </p>
                         <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a> 
                         <Link  to ="/Unitas-Services/WebDevelopment" className="btn btn-lg btn-primary rounded">
                            <i className="bi bi-arrow-right"></i>
                        </Link >
                        {/* <Navigate to="Unitas-Services/WebDevelopment" className="btn btn-lg btn-primary rounded" replace={true} ><i className="bi bi-arrow-right"></i></Navigate> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fa fa-chart-pie text-white"></i>
                        </div>
                        <h4 className="mb-3">Apps Development</h4>
                        <p className="m-0">"Our team of expert mobile developers is committed to turning your ideas into cutting-edge, user-friendly mobile applications."  If you need further assistance or have any other requests, we are happy to assist you </p>
                        {/* <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a> */}
                         <Link  to ="/Unitas-Services/AppDevelopment" className="btn btn-lg btn-primary rounded">
                            <i className="bi bi-arrow-right"></i>
                        </Link >
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fa fa-code text-white"></i>
                        </div>
                        <h4 className="mb-3">Cloud Solutions</h4>
                        <p className="m-0">We offer scalable and flexible cloud solutions to customers, allowing your product to seamlessly adopt to changing demand</p>
                        {/* <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a> */}
                         <Link  to ="#" className="btn btn-lg btn-primary rounded">
                            <i className="bi bi-arrow-right"></i>
                        </Link >
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fab fa-android text-white"></i>
                        </div>
                        <h4 className="mb-3">MVP Development</h4>
                        <p className="m-0">Accelerate the launch of your product! Our swift execution transforms your product vision into reality, enabling idea validation, user feedback collection, and efficient iteration.</p>
                        {/* <a className="btn btn-lg btn-primary rounded" href="">
                            <i className="bi bi-arrow-right"></i>
                        </a> */}
                         <Link  to ="#" className="btn btn-lg btn-primary rounded">
                            <i className="bi bi-arrow-right"></i>
                        </Link >
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fa fa-search text-white"></i>
                        </div>
                        <h4 className="mb-3">SEO Optimization</h4>
                        <p className="m-0">SEO writing is essential for creating content that ranks well on search engines like Google. We follow  some best practices for creating SEO-optimized content like finding write keyword, understanding search intent, etc.</p>
                        {/* <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a> */}
                         <Link  to ="#" className="btn btn-lg btn-primary rounded">
                            <i className="bi bi-arrow-right"></i>
                        </Link >
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fa fa-search text-white"></i>
                        </div>
                        <h4 className="mb-3">Design Services</h4>
                        <p className="m-0">We Provide wide range of Design Services lincluding illustrations of Brochures,Catlogs,Logo,Social Media Branding and much more.</p>
                        {/* <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a> */}
                         <Link  to ="/Unitas-Services/designServices" className="btn btn-lg btn-primary rounded">
                            <i className="bi bi-arrow-right"></i>
                        </Link >
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
};
