import {Link} from 'react-router-dom'
export default function NavbarR(){
    return(
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
            <Link  to ="/" className="navbar-brand p-0">
         <h1 className="m-0">
        <img src="../Asset/img/logo.png" alt="unitas Infotech logo" style={{Width:"80px",height:"55px"}}></img>
         </h1>
       </Link >
        <button
         className="navbar-toggler"
         type="button"
         data-bs-toggle="collapse"
         data-bs-target="#navbarCollapse"
       >
         <span className="fa fa-bars"></span>
       </button>
       <div className="collapse navbar-collapse" id="navbarCollapse">
       <div className="navbar-nav ms-auto py-0">
        <Link  to ="/" className="nav-item nav-link active">
                Home
            </Link >
            <Link  to ="/About" className="nav-item nav-link">
                About
            </Link >
            <Link  to ="/Services" className="nav-item nav-link">
                Services
            </Link >
            {/* <Link  to ="/Services" className="nav-item nav-link">
                Product
            </Link > */}
            <Link  to ="/Contact" className="nav-item nav-link">
                Contact
            </Link >
            
        </div>
        </div>    
           
        </nav>
    )
}

//   <div className="container-fluid position-relative p-0">
  //   <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
  //     <NavLink  to ="/" className="navbar-brand p-0">
  //       <h1 className="m-0">
  //       <img src="../Asset/img/logo.png" alt="unitas Infotech logo" style={{Width:"80px",height:"55px"}}></img>
  //       </h1>
  //     </NavLink >
  //     <button
  //       className="navbar-toggler"
  //       type="button"
  //       data-bs-toggle="collapse"
  //       data-bs-target="#navbarCollapse"
  //     >
  //       <span className="fa fa-bars"></span>
  //     </button>
  //     <div className="collapse navbar-collapse" id="navbarCollapse">
  //       <div className="navbar-nav ms-auto py-0">
  //       <NavLink  to ="#" className="nav-item nav-link active">
  //           Home
  //         </NavLink >
  //         <NavLink  to ="#" className="nav-item nav-link">
  //           About
  //         </NavLink >
  //         <NavLink  to ="#" className="nav-item nav-link">
  //           Services
  //         </NavLink >
  //         <div className="nav-item dropdown">
  //         <NavLink  to ="#"
  //             className="nav-link dropdown-toggle"
  //             data-bs-toggle="dropdown"
  //           >
  //             Blog
  //           </NavLink >
  //           <div className="dropdown-menu m-0">
  //           <NavLink  to ="#" className="dropdown-item">
  //               Blog Grid
  //             </NavLink >
  //             <NavLink  to ="#" className="dropdown-item">
  //               Blog Detail
  //             </NavLink >
  //           </div>
  //         </div>
  //         <div className="nav-item dropdown">
  //         <NavLink  to ="#"
  //             className="nav-link dropdown-toggle"
  //             data-bs-toggle="dropdown"
  //           >
  //             Pages
  //           </NavLink >
  //           <div className="dropdown-menu m-0">
  //           <NavLink  to ="#" className="dropdown-item">
  //               Pricing Plan
  //             </NavLink >
  //             <NavLink  to ="#" className="dropdown-item">
  //               Our features
  //             </NavLink >
  //             <NavLink  to ="#" className="dropdown-item">
  //               Team Members
  //             </NavLink >
  //             <NavLink  to ="#" className="dropdown-item">
  //               Testimonial
  //             </NavLink >
  //             <NavLink  to ="#" className="dropdown-item">
  //               Free Quote
  //             </NavLink >
  //           </div>
  //         </div>
  //         <NavLink  to ="#" className="nav-item nav-link">
  //           Contact
  //         </NavLink >
  //       </div>
  //     </div>
  //   </nav>
  // </div>