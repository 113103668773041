import React from "react";

export const MobileApp = () => {
    return (
        <div>
             <div className="container-fluid bg-dark px-5 d-none d-lg-block">
            <div className="row gx-0"></div>
          </div>
          <div className="container-fluid position-relative p-0">
            <div
              className="container-fluid bg-primary py-5 bg-header"
              style={{ marginBottom: "90px" }}
            >
              <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                  <h1 className="display-4 text-white animated zoomIn">Services</h1>
                  <a href="#" className="h5 text-white">
                    Home
                  </a>
                  <i className="far fa-circle text-white px-2"></i>
                  <a href="#" className="h5 text-white">
                    Services
                  </a>
                  <i className="far fa-circle text-white px-2"></i>
                  <a href="#" className="h5 text-white">
                    App Development
                  </a>
                </div>
              </div>
            </div>
          </div> 
          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                    <h5 className="fw-bold text-primary text-uppercase">Services</h5>
            </div>
            <div className="row g-5">
              <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/frontend.png" height="60px" width="60px" alt=""></img>
                            </div>
                            <h4 className="mb-3">React Native App Development</h4>
                            <p className="m-0">We provide cutting-edge front-end development services designed to elevate your product into a user-focused gem. Our front-end technology solutions enhance usability, offer intuitive navigation, and create visually appealing interfaces, guaranteeing an exceptional user experience.
                            <br/>
                            <br/>
                            <br/>
                            </p>
                         
                          </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/backend.jpg" height="65px" width="65px" alt=""></img>
                            </div>
                            <h4 className="mb-3">Flutter App Development</h4>
                            <p className="m-0">Our skilled team utilizes cutting-edge technologies to develop a robust and scalable back end, boosting performance, security, and data management. From efficient databases to resilient APIs, we guarantee seamless integration for a dependable and dynamic user experience.
                              <br/>
                              <br/>             
                              <br/>
                            </p>
                         
                          </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/frontend.png" height="60px" width="60px" alt=""></img>
                            </div>
                            <h4 className="mb-3">Iconic App Developmet</h4>
                            <p className="m-0">With our powerful cloud solutions, we offer scalability and flexibility, allowing your product to seamlessly adapt to changing demands. Experience unparalleled growth and success as we optimize your product's infrastructure through our exceptional cloud services.
                              <br/>
                              <br/>             
                              <br/>
                              <br/>
                            </p>
                          </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/frontend.png" height="60px" width="60px" alt=""></img>
                            </div>
                            <h4 className="mb-3">Responsive Web Development </h4>
                            <p className="m-0">With our expertise in front-end frameworks, we build responsive websites that seamlessly adapt to different screen sizes, guaranteeing an optimal user experience on desktop devices.
                              <br/>
                              <br/>             
                              <br/>
                              <br/>
                              <br/>
                            </p>
                          </div>
                    </div> */}
                    {/*                     
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                            <i class="fi fi-rs-chart-line-up"></i>
                                
                            </div>
                            <h4 className="mb-3">Performance Optimization</h4>
                            <p className="m-0">Our team employs industry best practices to optimize the performance of your website, ensuring fast load times and smooth navigation, resulting in enhanced user satisfaction.
                              <br/>
                              <br/>             
                              <br/>
                              <br/>
                              <br/>             
                              <br/>
                            </p>
                         
                          </div>
                    </div>
                     */}

                    {/* <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/frontend.png" height="60px" width="60px" alt=""></img>
                            </div>
                            <h4 className="mb-3">Cross Browser Campatibility</h4>
                            <p className="m-0">Our skilled developers ensure that your website performs flawlessly across various browsers, ensuring a consistent and reliable experience for your users.
                            <br/>
                              <br/>             
                              <br/>
                              <br/>
                              <br/>             
                              <br/>
                            </p>
                         
                          </div>
                    </div> */}
            </div>
            
            </div>
            </div>
            
        </div>
      );
}