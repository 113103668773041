import React from "react";
export const DesignServices = () => {
    return (
        <div>
             <div className="container-fluid bg-dark px-5 d-none d-lg-block">
            <div className="row gx-0"></div>
          </div>
          <div className="container-fluid position-relative p-0">
            <div
              className="container-fluid bg-primary py-5 bg-header"
              style={{ marginBottom: "90px" }}
            >
              <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                  <h1 className="display-4 text-white animated zoomIn">Services</h1>
                  <a href="#" className="h5 text-white">
                    Home
                  </a>
                  <i className="far fa-circle text-white px-2"></i>
                  <a href="#" className="h5 text-white">
                    Services
                  </a>
                  <i className="far fa-circle text-white px-2"></i>
                  <a href="#" className="h5 text-white">
                    Design Services 
                  </a>
                </div>
              </div>
            </div>
          </div> 
          <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                    <h5 className="fw-bold text-primary text-uppercase">Design Services</h5>
            </div>
            <div className="row g-5">
              <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/frontend.png" height="60px" width="60px" alt=""></img>
                            </div>
                            <h4 className="mb-3">Logo Design</h4>
                            <p className="m-0">Your brand deserves a logo that not only looks great but also tells your story. At Unitas Infotech, we specialize in creating unique, impactful logos that resonate with your audience.Our team of designers brings innovative ideas to life.<br/>
                            <br/>
                            <br/>
                            <br/>
                            </p>
                         
                          </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/backend.jpg" height="65px" width="65px" alt=""></img>
                            </div>
                            <h4 className="mb-3">Brochure & Catlogs</h4>
                            <p className="m-0">To present your products and services in an eye-catching, informative way. <br/>We at Unitas Infotech, we design brochures and catalogs that not only captivate but also convert.We create visually appealing layouts that draw in your audience.Each brochure and catalog is customized to meet your specific needs and brand identity.
                              <br/>
                              <br/>             
                              <br/>
                            </p>
                         
                          </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/frontend.png" height="60px" width="60px" alt=""></img>
                            </div>
                            <h4 className="mb-3">Company Profile</h4>
                            <p className="m-0">At Unitas Infotech, we pride ourselves on specific attribute, e.g., innovation, creativity, customer-centric approach. Our mission has always been to deliver exceptional design solutions that drive business success.
                              <br/>
                              <br/>             
                              <br/>
                              <br/>
                              <br/>
                            </p>
                          </div>
                    </div> 
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/frontend.png" height="60px" width="60px" alt=""></img>
                            </div>
                            <h4 className="mb-3">Packaging Design</h4>
                            <p className="m-0">First impressions matter. Make yours unforgettable with custom packaging design from Unitas Infotech. Our creative team turns your products into memorable experiences with designs that stand out on the shelf and resonate with your customers..
                              <br/>
                              <br/>             
                              <br/>
                              <br/>
                            </p>
                          </div>
                    </div>
                  
                                         
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                            <i class="fi fi-rs-chart-line-up"></i>
                                
                            </div>
                            <h4 className="mb-3">Social Media</h4>
                            <p className="m-0">In today’s digital age, a strong social media presence is essential for any business. At Unitas Infotech, we help you connect with your audience and build your brand through strategic social media management.
                              <br/>
                              <br/>             
                              <br/>
                              <br/>
                              <br/>             
                              <br/>
                            </p>
                         
                          </div>
                    </div>
                     

                    {/* <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <img src="../Asset/img/frontend.png" height="60px" width="60px" alt=""></img>
                            </div>
                            <h4 className="mb-3">Cross Browser Campatibility</h4>
                            <p className="m-0">Our skilled developers ensure that your website performs flawlessly across various browsers, ensuring a consistent and reliable experience for your users.
                            <br/>
                              <br/>             
                              <br/>
                              <br/>
                              <br/>             
                              <br/>
                            </p>
                         
                          </div>
                    </div> */}
            </div>
            
            </div>
            </div>
            
        </div>
      );
}