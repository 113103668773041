export default function About() {
  return (
    <div>
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner"></div>
        </div> */}

      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0"></div>
      </div>

      <div className="container-fluid position-relative p-0">
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">About Us</h1>
              <a href="#" className="h5 text-white">
                Home
              </a>
              <i className="far fa-circle text-white px-2"></i>
              <a href="#" className="h5 text-white">
                About
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="searchModal" tabindex="-1">
        <div className="modal-dialog modal-fullscreen">
          <div
            className="modal-content"
            style={{ background: "rgba(9, 30, 62, .7)" }}
          >
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="input-group" style={{ maxWidth: "600px" }}>
                <input
                  type="text"
                  className="form-control bg-transparent border-primary p-3"
                  placeholder="Type search keyword"
                />
                <button className="btn btn-primary px-4">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  About Us
                </h5>
                <h1 className="mb-0">Who we are ? </h1>
              </div>
              <p className="mb-4">
                Unitas Infotech is a User Experience & quality-focused digital
                engineering company that enables enterprises to build and run
                cutting-edge digital business models. Backed by a team of 50+
                passionate engineers and designers, we constitute an
                engineering-driven, people-first culture. We have been providing
                quality solutions to our customers.
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>User
                    Experience
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>
                    Scalable Web and Mobile Apps
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>24/7
                    Leadership
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Product
                    Development
                  </h5>
                </div>
              </div>
              {/* <div
                className="d-flex align-items-center mb-4 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
              </div>
              <a
                href="quote.html"
                className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                data-wow-delay="0.9s"
              >
                Request A Quote
              </a> */}
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src="Asset/img/about.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="container-fluid py-5 wow fadeInUp bg-primary"
        data-wow-delay="0.1s"
      >
        <div className="section-title position-relative pb-3 mb-5">
          <h5 className="fw-bold text-light text-center text-uppercase">
            Our mission
          </h5>
        </div>
        <p className="mb-4 text-center text-light">
          Unitas Infotech believe in, efficiency in delivering work at an
          optimal cost and lesser time, and integrity in maintaining honesty and
          transparency with our team members and clients.
        </p>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            width: "200px",
            height: "200px",
          }}
        ></div>
      </div> */}
      <div className="container-fluid py-5 bg-primary wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-light text-uppercase">Our mission</h5>
            {/* <h1 className="mb-0">
              Professional Stuffs Ready to Help Your Business
            </h1> */}
          </div>
          <div className="row g-5 " >
            <p className="text-center text-light">
              Unitas Infotech believe in, efficiency in delivering work at an
              optimal cost and lesser time, and integrity in maintaining honesty
              and transparency with our team members and clients.
            </p>
            <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden"></div>
                <div className="text-center py-4">
                  <h4 className="text-primary">People First </h4>
                  <p className="m-0" style={{padding: "4px"}}>
                     We encourage the growth of our teammates and reward their
                    achievements. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 wow slideInUp" data-wow-delay="0.6s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden"></div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Quality Coding </h4>
                  <p className="m-0" style={{padding: "4px"}}>
                    We strongly uphold the impact of clean, readable code
                    through every product.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden"></div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Ownership</h4>
                  <p className="m-0" style={{padding: "4px"}}>
                    We perform as owners and we are individually accountable for
                    what we deliver.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 wow slideInUp" data-wow-delay="0.9s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden"></div>
                <div className="text-center py-4">
                  <h4 className="text-primary">User Perspective</h4>
                  <p className="m-0" style={{padding: "4px"}}>
                  having a clear picture of their needs, tastes, preferences, and consumption patterns
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5 mb-5">
                <div className="bg-white">
                    <div className="owl-carousel vendor-carousel">
                        <img src="asset/img/vendor-1.jpg" alt="" />
                        <img src="asset/img/vendor-2.jpg" alt="" />
                        <img src="asset/img/vendor-3.jpg" alt="" />
                        <img src="asset/img/vendor-4.jpg" alt="" />
                        <img src="asset/img/vendor-5.jpg" alt="" />
                        <img src="asset/img/vendor-6.jpg" alt="" />
                        <img src="asset/img/vendor-7.jpg" alt="" />
                        <img src="asset/img/vendor-8.jpg" alt="" />
                        <img src="asset/img/vendor-9.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div> */}

      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
        <i className="bi bi-arrow-up"></i>
      </a>
    </div>
  );
}
