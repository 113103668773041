import React from "react";
import NavbarR from './nav-bar'
export const Header = () => {
  return (
    <div>
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              className="d-inline-flex align-items-center"
              style={{height: "45px"}}
            >
              
              <small className="me-3 text-light">
                <i className="fa fa-phone-alt me-2"></i>+91 8830 155 313
              </small>
              <small className="text-light">
                <i className="fa fa-envelope-open me-2"></i>info@unitasinfotech.com
              </small>
            </div>
          </div>
          {/* <div className="col-lg-4 text-center text-lg-end">
            <div
              className="d-inline-flex align-items-center"
              style={{height: "45px"}}
            >
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="#"
              >
                <i className="fab fa-twitter fw-normal"></i>
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href=""
              >
                <i className="fab fa-facebook-f fw-normal"></i>
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href=""
              >
                <i className="fab fa-linkedin-in fw-normal"></i>
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href=""
              >
                <i className="fab fa-instagram fw-normal"></i>
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
                href=""
              >
                <i className="fab fa-youtube fw-normal"></i>
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container-fluid position-relative p-0">
          <NavbarR />
      </div>
     {/* <div
      id="header-carousel"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img className="w-100" src="Asset/img/carousel-1.jpg" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: "900px"}}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                Creative & Innovative
              </h5>
              <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                Creative & Innovative Digital Solution
              </h1>
              <a
                href="quote.html"
                className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              >
                Free Quote
              </a>
              <a
                href=""
                className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img className="w-100" src="../Asset/img/carousel-2.jpg" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: "900px"}}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                Creative & Innovative
              </h5>
              <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                Creative & Innovative Digital Solution
              </h1>
              <a
                href="quote.html"
                className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              >
                Free Quote
              </a>
              <a
                href=""
                className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div> */}
    </div>
  );
};
