import React from "react";
import { Route,Routes } from 'react-router-dom'
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { Services } from "./components/Services";
import { Footer } from './components/Footer';
import About from "./components/about";
import Contact from "./components/contact";
import { WebDevelopment } from "./components/Unitas-Services/webdevelopment";
import { MobileApp } from "./components/Unitas-Services/appDevelopment";
import { DesignServices } from "./components/Unitas-Services/designServices";
import { NotFound } from './components/NotFound';
function App() {
  return (
  <>
    <Header />
    <div>
       <Routes>
        <Route path="/" element={<Home /> } />
        <Route path="/about" element={<About /> } />
        <Route path="/Services" element={<Services /> } />
        <Route path="/contact" element={<Contact /> } />
        <Route path="/Unitas-Services/webdevelopment" element={<WebDevelopment /> } />
        <Route path="/Unitas-Services/AppDevelopment" element={<MobileApp /> } />
        <Route path="/Unitas-Services/designServices" element={<DesignServices /> } />
        <Route path="*" element={<NotFound />} />
       </Routes>
    </div>
    < Footer />
  </>
  );
}

export default App;
