import React from "react";

export const NotFound = () => {
  return (
    <div>
         {/* <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0"></div>
      </div>
      <div className="container-fluid position-relative p-0">
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Services</h1>
              <a href="#" className="h5 text-white">
                Home
              </a>
              <i className="far fa-circle text-white px-2"></i>
              <a href="#" className="h5 text-white">
                Services
              </a>
            </div>
          </div>
        </div>
      </div> */}
404 not found
    </div>
  );
};